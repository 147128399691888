import React, { useState } from 'react'
import skeletonLoadingBoxes from './skeletonLoadingBoxes'
import { triggerSnackbar } from '../components/Snackbar'
import KeywordSearch from './KeywordSearch'
import SmartSuggestions from './SmartSuggestions'
import { SuggestionCardProps } from './SuggestionCard'

type SelfServiceWidgetProps = {
  inboxSlug: string
  dialogSlug: string
  mailCommentId: number | null
  suggestionsLoadedCallback: (suggestionsCount: number) => void
  actionTakenCallback: () => void
  insertLinkFunction: (url: string, title: string) => void
  setEditableFunction: (state: boolean) => void
  isConnectedToSmartReplyChannel: boolean
  hasSmartReplyChannels: boolean
  hasInboxEditPermissions: boolean
}

enum ErrorCode {
  ServerError = 1,
  LastMessageIsOutgoing = 2,
  ContentLengthExceeded = 3,
}

export default function SelfServiceWidget(props: SelfServiceWidgetProps) {
  const {
    inboxSlug,
    dialogSlug,
    mailCommentId,
    actionTakenCallback,
    suggestionsLoadedCallback,
    insertLinkFunction,
    setEditableFunction,
    isConnectedToSmartReplyChannel,
    hasSmartReplyChannels,
    hasInboxEditPermissions,
  } = props

  const [keywordSearchHits, setKeywordSearchHits] = useState<SuggestionCardProps[] | null>(null)
  const [smartSuggestions, setSmartSuggestions] = useState<SuggestionCardProps[] | null>(null)

  const requestSmartReply = (url: string) => {
    actionTakenCallback()
    displaySkeletonLoadingBoxes()
    setEditableFunction(false)
    removeExistingReply()

    $.get(url, function () {})
      .done(function (data) {
        if (data.error) {
          console.debug(data.error)
          if (ErrorCode.ContentLengthExceeded === data.error.code) {
            triggerSnackbar(gettext('Ett fel uppstod - guide eller mail är för långt.'))
          } else {
            triggerSnackbar(gettext('Tekniskt fel, försök igen senare.'))
          }
          return
        }

        const wrappedReply =
          '<div class="smart-replies-froala-content--reply">' + data.smartReply + '</div>'

        insertHTMLInFroalaEditor(wrappedReply)
      })
      .fail(function () {
        triggerSnackbar(gettext('Tekniskt fel, försök igen senare.'))
      })
      .always(function () {
        setEditableFunction(true)
        hideSkeletonLoadingBoxes()
      })
  }

  const insertLink = (title: string, url: string) => {
    actionTakenCallback()
    insertLinkFunction(url, title)
  }

  const keywordSearchPerformed = keywordSearchHits != null

  return (
    <>
      {isConnectedToSmartReplyChannel && (
        <KeywordSearch
          inboxSlug={inboxSlug}
          dialogSlug={dialogSlug}
          mailCommentId={mailCommentId}
          onRequestSmartReply={requestSmartReply}
          onRequestInsertLink={insertLink}
          setSearchHits={setKeywordSearchHits}
          searchHits={keywordSearchHits}
        />
      )}

      {!keywordSearchPerformed && (
        <SmartSuggestions
          inboxSlug={inboxSlug}
          dialogSlug={dialogSlug}
          mailCommentId={mailCommentId}
          suggestionsLoadedCallback={suggestionsLoadedCallback}
          isConnectedToSmartReplyChannel={isConnectedToSmartReplyChannel}
          hasSmartReplyChannels={hasSmartReplyChannels}
          hasInboxEditPermissions={hasInboxEditPermissions}
          onRequestSmartReply={requestSmartReply}
          onRequestInsertLink={insertLink}
          setSuggestions={setSmartSuggestions}
          suggestions={smartSuggestions}
        />
      )}
    </>
  )
}

const hideSkeletonLoadingBoxes = () => {
  document.querySelector('.smart-replies-froala-content--loader')?.remove()
}

const insertHTMLInFroalaEditor = (html: string) => {
  const froalaEditor = document.querySelector('div.fr-element')
  froalaEditor?.insertAdjacentHTML('afterbegin', html)
}

const displaySkeletonLoadingBoxes = () => {
  const froalaEditor = document.querySelector('div.fr-element')
  if (froalaEditor?.querySelector('.smart-replies-froala-content--loader')) {
    return
  }
  insertHTMLInFroalaEditor(skeletonLoadingBoxes())
}

const removeExistingReply = () => {
  document.querySelectorAll('.smart-replies-froala-content--reply').forEach((elem) => elem.remove())
}
