import React from 'react'

import { createRoot } from 'react-dom/client'
import SelfServiceWidget from './SelfServiceWidget'

export default function initSelfServiceWidget(
  elem,
  inboxSlug,
  dialogSlug,
  mailCommentId,
  suggestionsLoadedCallback,
  actionTakenCallback,
  insertLinkFunction,
  setEditableFunction,
  isConnectedToSmartReplyChannel,
  hasSmartReplyChannels,
  hasInboxEditPermissions,
) {
  if (elem && dialogSlug) {
    const root = createRoot(elem)
    root.render(
      <SelfServiceWidget
        inboxSlug={inboxSlug}
        dialogSlug={dialogSlug}
        mailCommentId={mailCommentId}
        suggestionsLoadedCallback={suggestionsLoadedCallback}
        actionTakenCallback={actionTakenCallback}
        insertLinkFunction={insertLinkFunction}
        setEditableFunction={setEditableFunction}
        isConnectedToSmartReplyChannel={isConnectedToSmartReplyChannel}
        hasSmartReplyChannels={hasSmartReplyChannels}
        hasInboxEditPermissions={hasInboxEditPermissions}
      />,
    )
  }
}
